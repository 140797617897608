import { useEffect } from 'react'
import { Container, Alert } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { getPosts } from '../projectsData'

export default function Projects()  {

        useEffect(() => {
            document.title = "Michael Njogu | UX/UI Designer"
        }, [])

        let posts = getPosts()

        return (
            <div className="wrapper">

                <section className="content-section content-section-large section-page-title">
                    <Container>
                        <h1 className="page-title mb-2">Hi, I'm Michael Njogu.👋🏾</h1>
                        <p className="lead">I am a self-motivated UX/UI designer <strong>passionate</strong> about <strong>making a difference</strong> in people's lives through <strong>design</strong>.</p>
                        <Link className="styled-link" to="/about">More about me</Link>
                    </Container>
                </section>

                <section className="content-section pt-0">
                    <Container>
                        <h2 className="section-title">* Featured Work</h2>
                        <p className="small mb-4 text-secondary">* As a result of signing an NDA with my current organization, I am restricted from sharing any work samples or projects I have worked on.</p>
                        <div className="projects-grid-container">
                            {posts.map(({ id, title, thumbnail, thumbnail_alt, desc, categories }) => (
                            <div className="project-item" key={id}>
                                <div className="project-item-img">
                                    <Link to={`/work/${id}`}>
                                        <img loading="lazy" src={thumbnail} alt={thumbnail_alt} height="297" />
                                    </Link>
                                </div>
                                <div className="project-item-info">
                                    <h3><Link to={`/work/${id}`}>{title}</Link></h3>
                                    <p>{desc}</p>
                                    <ul className="categories">
                                        {categories.map((category) => (
                                            <li key={category}><span>{category}</span></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>                                
                            ))}
                        </div>
                    </Container>
                </section>

            </div>
        )

}

