import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'

export default function NotFound() {

    useEffect(() => {
        document.title = "Page Not Found"
    }, [])    

    return (
        <div className="wrapper">  
            <section className="content-section content-404">
                <Container>
                    <FontAwesomeIcon size="3x" icon={faExclamation} />
                    <h1 className="page-title">Oops!</h1>
                    <h2 className="section-title">Something Went Wrong!</h2>
                    <p>It seems like the page or resource you are looking for does not exist on this site, or may have been permanently deleted.</p>
                    <Link className="styled-link styled-link-full" to="/" title="Go back to the homepage">Take me back home</Link>
                </Container>
            </section>              
        </div>
    )
}