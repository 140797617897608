import { useEffect } from 'react'
import Hero from '../components/ui/Hero'
import ProjectNav from '../components/ui/ProjectNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap'
import Zoom from 'react-medium-image-zoom'

export default function FamilyBank() {

        useEffect(() => {
            document.title = "Family Bank Website | Michael Njogu"
        }, [])

        return (
            <div className="wrapper">

            <Hero url="../images/projects/family-bank/fbl-hero.jpg" alt="Hero image for Family Bank project" />      

            <ProjectNav />

            <section className="content-section pb-0">
                <Container>

                    <h1 className="page-title project-title">Family Bank Website</h1>
                    <p className="lead">Redesigning the website with the customer in mind.</p>

                    <div className="project-info">

                        <div className="project-info-item">
                            <h5>My Role</h5>
                            <ul>
                                <li>UX Research</li>
                                <li>Visual Design</li>
                                <li>UX Writing</li>
                                <li>Frontend Design (HTML &amp; CSS)</li>
                            </ul>
                        </div>
                 

                        <div className="project-info-item">
                            <h5>Team</h5>
                            <ul>
                                <li>Triza Maina <span>(Project Lead)</span></li>
                                <li>George Muhia <span>(UX Research)</span></li>
                                <li>Mohammed Ali <span>(Copywriting)</span></li>
                            </ul>
                        </div>                     

                    </div>                    

                </Container>
            </section>

            <section className="content-section pb-0" id="context">
                <Container>
                    <h2 className="section-title">Context</h2>
                    <h5>Introduction</h5>
                    <p>Family Bank, one of Kenya's fastest-growing banks, faced a critical challenge - an outdated website design that hindered its competitiveness against rivals in the banking industry. To maintain their position in the market and improve customer engagement, we embarked on a website redesign project focused on user-centric solutions and effective communication to convert visitors into loyal customers.</p>
                    <h5>My Role</h5>
                    <p>In this project, I played a crucial role in various stages. I spearheaded the user survey, crafted high-fidelity mockups, wrote engaging UI copy, and developed static HTML templates to be integrated into a customized content management system.</p>
                </Container>
            </section> 

            <section className="content-section pb-0" id="process">
                <Container>
                    <h2 className="section-title">Process</h2>
                    <h5>Research</h5>
                    <p>To gain valuable insights into customer experiences with banking websites, we conducted a comprehensive survey. Our findings revealed that 36% of respondents visited their bank's website weekly. We explored what users liked and disliked about their current banking websites.</p>
                    <p className="lead">Q: What <strong>do</strong> you like about your bank's website?</p>
                    <div className="quotes mb-3 mt-3">
                            <blockquote className="quote">
                                <p className="mb-0">“Easy to navigate, always updates me on new products announcements and it's up to date with the global banking trends”</p>
                            </blockquote>
                            <blockquote className="quote"><p className="mb-0">“Secure. Fast. Easy to use”</p></blockquote>
                            <blockquote className="quote"><p className="mb-0">“It’s colorful and easy on the eye. Aesthetic and also easy to navigate on mobile”</p></blockquote>
                    </div>
                    <p className="lead">Q: What <strong>don't</strong> you like about your bank's website?</p>
                    <div className="quotes mb-5 mt-3">
                            <blockquote className="quote"><p className="mb-0">“Look &amp; feel”</p></blockquote>
                            <blockquote className="quote"><p className="mb-0">“Takes too long to load, old fashioned design”</p></blockquote>
                            <blockquote className="quote"><p className="mb-0">“Not much information on the extra online services they provide”</p></blockquote>
                    </div>

                    <h5>Findings</h5>   
                    <p>Based on the survey results, we identified four key touchpoints significantly influencing user interactions:</p>
                    <ul className="fa-ul mb-5">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Ease of use</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Speed</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Mobile responsiveness</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Design</li>
                    </ul>

                    <h5>Website Audit</h5>
                    <p>Conducting a detailed audit of Family Bank's existing website, we pinpointed several challenges affecting the identified touchpoints:</p>
                    <ul className="fa-ul">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Complex navigation:</strong> Users required more than three clicks to access product or service details.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Lack of mobile responsiveness:</strong> The website did not adapt well to mobile devices.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Inconsistent branding:</strong> Colors and fonts deviated from the company's brand guidelines.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Content issues:</strong> Lack of concise and informative information on products and services.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Slow loading speed:</strong> Pages took an average of 10 seconds to load.</li>                    
                    </ul>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img src="../images/projects/family-bank/family-bank-old-snapshot.jpg" alt="A snapshot of the old website on mobile" width="700" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>A snaspshot of the website at the time of briefing</p>
                            </div>
                        </div>
                    </div>

                    <h5>Understanding the Customer</h5>
                    <p>To build empathy and create better user experiences, I developed customer journey maps and defined key personas.</p>
                    <div className="image-grid grid-2 mb-4">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/customer-journey-map-1.jpg" alt="Customer journey map" />
                            </Zoom>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/customer-journey-map-2.jpg" alt="Customer journey map" />
                            </Zoom>
                        </div>
                    </div>

                    <h5>Rebuilding the Site Navigation</h5>
                    <p>With a focus on reducing user frustration, we revamped the website navigation, minimizing the number of clicks required to access products and services.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/personal-banking.jpg" alt="Personal banking" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Navigation structure for personal banking</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/business-banking.jpg" alt="Business banking" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Navigation structure for business banking</p>
                            </div>
                        </div>
                    </div>

                    <h5>Designing the Solution</h5>
                    <p>Using Adobe XD, I designed high-fidelity mockups that captured a cohesive and visually appealing user interface. These mockups were then converted into static HTML templates, ready for integration into the content management system.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/nav-showcase.jpg" alt="Business banking" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Grouping similar items in a "megamenu"</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/mobile-nav.jpg" alt="Business banking" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>I designed the navigation to adapt to mobile devices</p>
                            </div>
                        </div>
                    </div>

                    <h5>Rewriting Content for Humans</h5>
                    <p>Collaborating closely with the copywriter, we crafted engaging and informative content, keeping it concise and relevant to ensure easy comprehension.</p>
                    <ul className="fa-ul">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>We kept the content short and clear to be simply understood</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>We defined the content for each page to make sure it was relevant and not redundant</li>
                    </ul>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/cta-1.jpg" alt="" width="800" />
                            </Zoom>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/family-bank/cta-2.jpg" alt="" width="800" />
                            </Zoom>
                        </div>
                    </div>

                </Container>
            </section>

            <section className="content-section pb-0" id="results">
                <Container>
                    <h2 className="section-title">Results</h2>
                    <p>Following the website deployment, we closely monitored and analyzed performance data, achieving impressive outcomes:</p>
                    <ul className="fa-ul">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Reduced clicks to access products or services to less than 3.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Improved page loading speed by 15%.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>A significant 20% drop in the average bounce rate.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>The customer experience team reported a substantial increase in leads originating from the website.</li>
                    </ul>
                </Container>
            </section>

            <section className="content-section pb-0" id="challenges">
                <Container>
                    <h2 className="section-title">Challenges</h2>
                    <p>While striving for excellence, we encountered challenges due to conflicting stakeholder assumptions. We effectively addressed this issue by anchoring our design decisions with solid data and user insights.</p>
                </Container>
            </section>

            <section className="content-section" id="takeaways">
                <Container>
                    <h2 className="section-title">Takeaways</h2>
                    <p>Throughout this project, we learned valuable lessons:</p>
                    <h5>Aim for continuous improvement rather than perfection.</h5>
                    <p>"Think of your product as perpetually imperfect, as a prototype that will evolve over time through <strong>continuous innovation</strong>." <cite>- Raul Chao</cite></p>
                    <h5>User testing is an ongoing process</h5>
                    <p>User testing is an ongoing process to adapt to evolving markets and user needs.</p>
                </Container>
            </section>

        </div>            
        )
    }