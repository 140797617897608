import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './../components/layout/Header'
import Footer from './../components/layout/Footer'

class Layout extends React.Component {
    render() {
        return (
            <>
                <Header />
                    <Outlet />
                <Footer />
            </>
        )
    }
}

export default Layout

