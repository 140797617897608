import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './util/ScrollToTop'
import "@fontsource/dm-sans/400.css"
import "@fontsource/dm-sans/700.css"
import "@fontsource/dm-sans/500.css"
import 'react-medium-image-zoom/dist/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/css/base.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
);
