import { useEffect } from 'react'
import Hero from '../components/ui/Hero'
import ProjectNav from '../components/ui/ProjectNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap'
import Zoom from 'react-medium-image-zoom'

export default function ShellCustomerChampion() {

        useEffect(() => {
            document.title = "Shell Customer Champion App | Michael Njogu"
        }, [])

        return (
            <div className="wrapper">

            <Hero url="../images/projects/shell/shell-hero.jpg" alt="Hero image for Shell project" /> 

            <ProjectNav />

            <section className="content-section pb-0">
                <Container>

                    <h1 className="page-title project-title">Shell Customer Champion App</h1>
                    <p className="lead">Creating an engaging app experience to drive sales.</p>
                    <small className="text-muted font-weight-semi-bold"><FontAwesomeIcon className="me-2" icon={faCircleInfo} />To comply with my non-disclosure agreement, I have omitted confidential information from this case study.</small>

                    <div className="project-info">

                        <div className="project-info-item">
                            <h5>My Role</h5>
                            <ul>
                                <li>Visual Design</li>
                                <li>Prototyping</li>
                            </ul>
                        </div>               

                        <div className="project-info-item">
                            <h5>Team</h5>
                            <ul>
                                <li>Ian Wanjau <span>(Project Lead)</span></li>
                                <li>Ephraim Mwangi <span>(Content Strategy)</span></li>
                                <li>Dennis Mutua <span>(Art Direction)</span></li>
                            </ul>
                        </div>                     

                    </div>                    

                </Container>
            </section>

            <section className="content-section pb-0" id="context">
                <Container>
                    <h2 className="section-title">Context</h2>
                    <h5>The Problem</h5>
                    <p>Shell wanted their staff, dubbed <strong>customer champions</strong>, to care about and believe in the <strong>Shell V-Power brand</strong>, so that they'd be motivated to sell this to customers more frequently.</p>
                    <h5>The Solution</h5>
                    <p>We hypothesized that enabling Shell employees to use technology in innovative and useful ways would yield higher productivity. We built upon 3 key pillars:</p>
                    <ul className="fa-ul">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Education:</strong> equipping Shell customer champions with training material and mentorship opportunities</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Engagement:</strong> creating curated product experiences</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Rewards:</strong> rewarding performance through incentives and giveaways</li>
                    </ul>
                    <p>We saw an opportunity to enhance the user experience of the existing Shell Customer Champion app.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/shell/old-screens.jpg" alt="Screenshots of the Customer Champion app" width="800" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Screenshots of the existing Customer Champion app</p>
                            </div>
                        </div>
                    </div>
                    <h5>My Role</h5>
                    <p>I designed <strong>high-fidelity mockups</strong> and the <strong>prototype</strong> for the mobile application.</p>
                </Container>
            </section> 

            <section className="content-section pb-0" id="process">
                <Container>
                    <h2 className="section-title">Process</h2>

                    <h5>Insights</h5>
                    <p>From our research, we found out that a good number of staff at Shell fuel stations did not understand the Shell V-Power brand. What is different about Shell V-Power? What does Shell V-Power do for customers? What is the impact on the business? In some cases, they did not actually believe in the brand and felt disengaged.</p>

                    <h5>Redesigning the Onboarding Experience</h5>
                    <p>I redesigned the onboarding experience to <strong>educate</strong> the customer champions about the benefits of using the app.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/shell/first-onboarding-screens.jpg" alt="Onboarding screens" />
                            </Zoom>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/shell/last-onboarding-screens.jpg" alt="Onboarding screens" />
                            </Zoom>
                        </div>
                    </div>

                    <h5>Training Modules</h5>
                    <p>I worked on creating a more <strong>engaging</strong> experience for the training modules.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/shell/training-modules.jpg" alt="Training modules" width="800" />
                            </Zoom>
                        </div>
                    </div>

                    <h5>Dashboard &amp; Leaderboards</h5>
                    <p>I designed the look and feel of the dashboards and leaderboards.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/shell/quality-marshall-dashboard.jpg" alt="Quality marshall dashboard" />
                            </Zoom>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/shell/leaderboards.jpg" alt="Leaderboards" />
                            </Zoom>
                        </div>
                    </div>

                    <h5>Prototyping the Solution</h5>
                    <p>I created an digital prototype in Adobe XD to demonstrate the various user journeys.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/shell/prototyping.jpg" alt="" width="800" />
                            </Zoom>
                        </div>
                    </div>

                </Container>
            </section>

            <section className="content-section pb-0" id="results">
                <Container>
                    <h2 className="section-title">Results</h2>
                    <p>We presented our pitch deck to the client and even though we didn't beat the competition, it was a great learning experience for me.</p>
                </Container>
            </section>

            <section className="content-section pb-0" id="challenges">
                <Container>
                    <h2 className="section-title">Challenges</h2>
                    <p>Our biggest challenge as a team was the tendency to use pre-conceived ideas and bias from our research.</p>
                </Container>
            </section>

            <section className="content-section" id="takeaways">
                <Container>
                    <h2 className="section-title">Takeaways</h2>
                    <h5>Design thinking is a collaborative process</h5>
                    <p>Your team needs to collaborate efficiently in order to work successfully. Extend collaboration to everyone on a project team, not just the designers.</p>
                    <h5>Remember that you are not the user</h5>
                    <p>It's wrong to make assumptions about your users. Take time to learn about your user and how they interact with your designs.</p>
                    <h5>Don't focus too much on the tools</h5>
                    <p>Instead of getting hung up on what tools to use, focus on creative ways of problem solving and think of how to structure the design process to add value to the business.</p>
                </Container>
            </section>

        </div>            
        )
    }