import { useEffect } from 'react'
import Hero from '../components/ui/Hero'
import ProjectNav from '../components/ui/ProjectNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col } from 'react-bootstrap'
import Zoom from 'react-medium-image-zoom'
import { Link } from "react-router-dom"

export default function Sabasi() {

    useEffect(() => {
        document.title = "Sabasi Web & Mobile App | Michael Njogu"
    }, [])

        return (
            <div className="wrapper">

            <Hero url="../images/projects/sabasi/sabasi-hero.jpg" alt="Hero image for Sabasi project" />    

            <ProjectNav />

            <section className="content-section pb-0">
                <Container>

                    <h1 className="page-title project-title">Sabasi Web &amp; Mobile App</h1>
                    <p className="lead">Simplifying data collection with a free survey tool.</p>

                    <div className="project-info">

                        <div className="project-info-item">
                            <h5>My Role</h5>
                            <ul>
                                <li>Competitor Analysis</li>
                                <li>Wireframing</li>
                                <li>Visual Design</li>
                                <li>UX Writing</li>
                                <li>Prototyping &amp; Testing</li>
                            </ul>
                        </div>                

                        <div className="project-info-item">
                            <h5>Team</h5>
                            <ul>
                                <li>John Mucheke <span>(Research, Project Lead)</span></li>
                                <li>Geoffrey Muthami <span>(Senior Backend Developer)</span></li>
                                <li>Dennis Mwongera <span>(Frontend Developer - Vue.js)</span></li>
                                <li>Laurent Juma <span>(Android Developer - Kotlin)</span></li>
                            </ul>
                        </div>                     

                    </div>                    

                </Container>
            </section>

            <section className="content-section pb-0" id="context">
                <Container>
                    <h2 className="section-title">Context</h2>
                    <h5>The Problem</h5>
                    <p>Marginalised communities in Kenya have <strong>limited</strong> or <strong>no access</strong> to the Internet. Consequently, researchers in these communities primarily use printed questionnaires for data collection. This method is <strong>costly</strong>, <strong>time-consuming</strong> and involves <strong>long verification processes</strong>.</p>
                    <h5>The Solution</h5>
                    <p>We embarked on a 6-month-long project to develop <strong>Sabasi</strong>, a <strong>free</strong> survey application that would make data collection more <strong>efficient</strong>, improve <strong>transparency</strong> and promote <strong>accountability</strong> in data collection exercises.</p>
                    <h5>My Role</h5>
                    <p>I created the <strong>wireframes</strong>, <strong>high-fidelity mockups</strong>, <strong>digital prototypes</strong> and wrote the <strong>UI copy</strong> for the web and mobile applications. I also collaborated with the frontend developers to convert the designs to code.</p>
                </Container>
            </section> 

            <section className="content-section pb-0" id="process">
                <Container>
                    <h2 className="section-title">Process</h2>
                    <h5>Research &amp; Findings</h5>
                    <p>Open Institute had previously worked with <Link to="/work/kilifi-data-dashboard">Kilifi County</Link> in an exercise to help research assistants collect and visualise data on county development projects. We used the findings from this exercise to summarize the pain points:</p>
                    <ul className="fa-ul mb-5">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Using printed questionnaires can get <strong>costly</strong> if you plan to interview a large number of respondents</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>You risk <strong>losing data</strong> if filled questionnaires are damaged or misplaced</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>Data verification</strong> and <strong>analysis</strong> can only be done <strong>after</strong> the data collection exercise is complete</li>
                    </ul>

                    <h5>Defining User Needs</h5>
                    <p>We focused on users experiencing problems with existing data collection tools and ensured we got the right outcome for them.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/sabasi/user-needs.jpg" alt="A table showing user needs and goals" width="800" />
                            </Zoom>
                        </div>
                    </div>

                    <h5>Competitor Analysis</h5>
                    <p>One of our main objectives was to build a <strong>cost-effective</strong> data collection solution. With this in mind, I selected a few existing applications as benchmarks and did a comparison of <strong>cost</strong>, <strong>features</strong> and <strong>limitations</strong>.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/sabasi/competitor-analysis.jpg" alt="A table comparing various survey apps" width="800" />
                            </Zoom>
                        </div>
                    </div>

                    <h5>Ideation</h5>
                    <p>Throughout the design process we conducted virtual and physical brainstorm sessions to formulate ideas.</p>
                    <h6>Our Eureka Moment</h6>
                    <p>During one of our ideation sessions, we came up with a brilliant idea to try out a feature that would enable users to transfer responses from one device to another via Bluetooth when internet connectivity was down. We unanimously agreed that this was a must-have feature for the first release.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/sabasi/sabasi-mind-map.jpg" alt="A mind map generated from several brainstorming sessions" width="800" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>A mind map generated from several brainstorming sessions</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img className="d-block" src="../images/projects/sabasi/bluetooth-transfer.jpg" alt="Flow chart showing the Bluetooth transfer process" width="800" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>A simplified diagram of the Bluetooth transfer process</p>
                            </div>
                        </div>
                    </div>

                    <Row className="mb-5">
                        <Col lg="6">
                            <h5>Prioritising the Features</h5>
                            <p>We planned to release the first phase of Sabasi with these <strong>core features</strong>:</p>
                            <h6 className="text-muted">Web app (full features)</h6>
                            <ul className="fa-ul">
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Form builder with multiple question formats</li>
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Survey logic</li>
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Geolocation</li>
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Share published surveys via email &amp; social media</li>
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>View and export responses to CSV or PDF</li>
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Analysis of results in real time</li>
                            </ul>
                            <h6 className="text-muted">Mobile app (light version)</h6>
                            <ul className="fa-ul">
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Save responses offline when connectivity is limited</li>
                                <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>Export/import offline responses to/from other devices via Bluetooth</li>
                            </ul>
                        </Col>
                        <Col lg="6">
                            <div className="image-grid grid-1 mb-5">
                                <div className="image-grid-item">
                                    <Zoom zoomMargin={40}>
                                        <img loading="lazy" className="d-block" src="../images/projects/sabasi/feature-prioritization-matrix.jpg" alt="Feature prioritization matrix" width="500" />
                                    </Zoom>
                                    <div className="image-grid-item-caption">
                                        <p>A prioritization matrix for the features</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <h5>Low Fidelity Mockups</h5>
                    <p>I created up some low fidelity mockups get feedback from the developers.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/desktop-login.jpg" alt="User login screen" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Low fidelity mockup of the login page</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/user-dashboard.jpg" alt="User dashboard" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Low fidelity mockup of the user dashboard</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/fill-survey-mobile.jpg" alt="Filling a survey" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Filling a survey (wireframe)</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/bluetooth-transfer-wireframe.jpg" alt="Bluetooth transfer wireframe" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Bluetooth transfer process (wireframe)</p>
                            </div>
                        </div>
                    </div>

                    <h5>High Fidelity Mockups</h5>
                    <p>I created high fidelity mockups in <strong>Figma</strong> and collaborated with the frontend developers to ensure the HTML &amp; CSS matched the design.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/sabasi-login.jpg" alt="Login screen on the web app" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Web app login screen</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/web-app-dashboard.jpg" alt="User dashboard on the web app" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>User dashboard</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/hf-mobile-survey.jpg" alt="Filling a survey" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Filling a survey on the mobile app</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/sabasi/hf-pending-uploads.jpg" alt="Bluetooth transfer designs (mobile)" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Bluetooth transfer on the mobile app</p>
                            </div>
                        </div>
                    </div>

                    <h5>Testing</h5>
                    <p>We piloted a prototype of Sabasi with Lake Tanganyika Floating Health Clinic (LTFHC) in Bujumbura, Burundi. This was an opportunity for us to test the application in a practical setting. During this period, we made many discoveries that helped us redefine the <strong>user needs</strong> and <strong>challenges</strong> and formulate <strong>new ideas</strong>.</p>
                    <p><a href="https://openinstitute.africa/data-collection-made-for-africa/" rel="nofollow noreferrer" target="_blank">Read the blog post</a></p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <img className="d-block" src="../images/projects/sabasi/macbook-on-plane.jpg" alt="Macbook on a plane" width="800" />
                        </div>
                        <div className="image-grid-item">
                            <img className="d-block" src="../images/projects/sabasi/sabasi-training-burundi.jpg" alt="Training the team at LTFHC" width="800" />
                        </div>
                    </div>

                </Container>
            </section>

            <section className="content-section pb-0" id="results">
                <Container>
                    <h2 className="section-title">Results</h2>
                    <h5>Launch</h5>
                    <p>We launched the first release of Sabasi on <strong>Monday, 13th June 2022</strong> at <strong>5.00PM EAT</strong>.</p>
                    <p>I was fortunate to have worked with a diverse and talented team to make this product a reality in a short time.</p>
                    <p>Watch the launch video on <a href="https://www.youtube.com/watch?v=U_wIwHbS3x0" rel="nofollow noreferrer" target="_blank">YouTube</a>.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <img className="d-block" src="../images/projects/sabasi/sabasi-launch-1.jpg" alt="Sabasi Launch" width="800" />
                        </div>
                    </div>
                </Container>
            </section>

            <section className="content-section pb-0" id="challenges">
                <Container>
                    <h2 className="section-title">Challenges</h2>
                    <p>As a team, we faced some challenges during the project:</p>
                    <ul className="fa-ul">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>A tight delivery schedule required us to <strong>work smart</strong> and <strong>put in extra hours</strong></li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span><strong>New findings</strong> would sometimes alter design specifications</li>
                    </ul>
                </Container>
            </section>

            <section className="content-section" id="takeaways">
                <Container>
                    <h2 className="section-title">Takeaways</h2>
                    <h5>Test early on</h5>
                    <p>Finding out what doesn’t work early on is critical to long-term success.</p>
                    <h5>Avoid radical changes; make small incremental adjustments</h5>
                    <p>Many design problems you need to solve are isolated and can be fixed with small, incremental approaches</p>
                    <h5>Use a specific idea template</h5>
                    <p>Rapid ideation sessions are not always productive. It is important that all ideas include similar information if your team plans to compare or prioritize them.</p>
                </Container>
            </section>

        </div>            
        )
    }