import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

class LearningCard extends React.Component {
    render() {
        return (
            <>
                <div className="exp-item">
                    <h3>{this.props.title}</h3>
                    <p className="exp-source"><img src={this.props.icon} alt={this.props.alt} width="40" height="40" />{this.props.source}</p>
                    <div className="exp-item-bottom">
                    {this.props.link ?
                    <a className="exp-link" href={this.props.link} rel="nofollow noreferrer" target="_blank">
                        View credential
                        <FontAwesomeIcon className="menu-icon" icon={faUpRightFromSquare} />
                    </a>
                    :
                    <span class="exp-link">In progress</span>
                    }
                    </div>
                </div>
            </>
        )
    }
}

export default LearningCard