import React from 'react'
import { Routes, Route } from "react-router-dom";
import Projects from './pages/Projects'
import About from './pages/About'
import NotFound from './pages/NotFound'

// Projects
import Sabasi from './projects/Sabasi'
import FamilyBank from './projects/FamilyBank'
import KilifiOpenData from './projects/KilifiOpenData'
import Shell from './projects/Shell'
/*
import OpenCounty from './projects/OpenCounty'
import DDS from './projects/DDS'
*/

// Layout
import Layout from './pages/Layout'

class App extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/work/sabasi" element={<Sabasi />} />
          <Route path="/work/family-bank" element={<FamilyBank />} />
          <Route path="/work/kilifi-data-dashboard" element={<KilifiOpenData />} />
          <Route path="/work/shell-customer-champion-app" element={<Shell />} />
          <Route path="*" element={<NotFound />} />
          {/*
          <Route path="/work/open-county-web-app" element={<OpenCounty />} />
          <Route path="/work/dds-website" element={<DDS />} />
          */}
        </Route>
      </Routes>
    )
  }
}

export default App;
