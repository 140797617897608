import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Resume from './../../docs/Michael_K_Njogu_Resume_20221231.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare, faBarsStaggered } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component {
    render() {
        return (
            <>
            <header>
                <Navbar className="shadow-sm navbar-top" expand="lg">
                    <Container>
                        <Navbar.Brand href="/">MN.</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"><FontAwesomeIcon icon={faBarsStaggered} /></Navbar.Toggle>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto" as="ul">
                                    <Nav.Item as="li">
                                        <Nav.Link as={NavLink} to="/" >Work</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                        <Nav.Link as={NavLink} to="/about">About Me</Nav.Link>
                                    </Nav.Item>                                   
                                    <Nav.Item>
                                        <Nav.Link className="ext-link" href={Resume} rel="nofollow noreferrer" target="_blank" title="View my resume in a new tab">
                                            Resume
                                            <FontAwesomeIcon className="menu-icon" icon={faUpRightFromSquare} />
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            </>
        )
    }
}

export default Header