import React from 'react'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import BackToTop from 'react-back-to-top-button'

let currentYear = new Date().getFullYear()

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <Container>
                    <h4>Let's Connect.</h4>
                    <ul className="social-icons">
                        <li><a href="https://www.linkedin.com/in/michael-njogu/" rel="nofollow noreferrer" target="_blank" title="Connect with me on LinkedIn"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                        <li><a href="mailto:mykekunyo@gmail.com" rel="nofollow noreferrer" title="Send me an email"><FontAwesomeIcon icon={faEnvelope} /></a></li>                    
                    </ul>
                    <p className="colophon">Copyright &copy; {currentYear} &middot; Michael Njogu</p>
                </Container>
                <BackToTop showOnScrollUp showAt={100} speed={1500} easing="easeInOutQuint">
                    <span title="Scroll to top"><FontAwesomeIcon icon={faChevronUp} /></span>
                </BackToTop>
        </footer>
        )
    }
}

export default Footer