import React from "react"

class Hero extends React.Component {
    render() {
        return (
            <section className="project-hero">
                <img loading="lazy" src={this.props.url} alt={this.props.alt} height="450" />
            </section>
        )
    }
}

export default Hero