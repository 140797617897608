const posts = [
    {
        id: "sabasi",
        title: "Sabasi Web & Mobile App",
        desc: "Simplifying data collection with a free survey tool.",
        thumbnail: "./images/projects/sabasi-thumbnail.jpg",
        thumbnail_alt: "Mobile and desktop mockups of Sabasi",
        categories: [
            "UX/UI Design",
            "UX Writing",
        ]
    },
    {
        id: "kilifi-data-dashboard",
        title: "Kilifi Data Dashboard",
        desc: "Promoting open governance through data visualization.",
        thumbnail: "./images/projects/kilifi-data-thumbnail.jpg",
        thumbnail_alt: "Kilifi Data Dashboard",
        categories: [
            "UX/UI Design"
        ]
    },    
    {
        id: "family-bank",
        title: "Family Bank Website",
        desc: "Redesigning the website with the customer in mind.",
        thumbnail: "./images/projects/family-bank-thumbnail.jpg",
        thumbnail_alt: "Family Bank",
        categories: [
            "UX Research",
            "UX/UI Design",
            "UX Writing",
            "HTML & CSS"
        ]
    },    
    {
        id: "shell-customer-champion-app",
        title: "Shell Customer Champion App",
        desc: "Creating an engaging app experience to drive sales.",
        thumbnail: "./images/projects/shell-cc-thumbnail-2.jpg",
        thumbnail_alt: "Shell Customer Champion App",
        categories: [
            "UX/UI Design"
        ]
    }
]

export const getPost = (postId) => {
    return posts.find(({ id }) => postId === id)
}
  
export const getPosts = () => {
    return posts.map(({ id, title, desc, thumbnail, thumbnail_alt, categories }) => {
        return ({ id, title, desc, thumbnail, thumbnail_alt, categories });
    });
}

export default posts