import React from 'react'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Scrollspy from 'react-scrollspy'

class ProjectNav extends React.Component {
    render() {
        return (
            <section className="project-nav sticky-top">
            <Container>
                <div className="project-nav-inner">
                    <h6><Link to="/"><FontAwesomeIcon icon={faArrowLeft} /><span>Back to featured work</span></Link></h6>
                    <ul>
                        <Scrollspy items={ ['context', 'process', 'results', 'challenges', 'takeaway'] } currentClassName="is-current">
                            <li><a href="#context">Context</a></li>
                            <li><a href="#process">Process</a></li>
                            <li><a href="#results">Results</a></li>
                            <li><a href="#challenges">Challenges</a></li>
                            <li><a href="#takeaways">Takeaways</a></li>
                        </Scrollspy>
                    </ul>
                </div>
            </Container>
            </section>
        )
    }
}

export default ProjectNav