import React, { useEffect } from 'react'
import Hero from '../components/ui/Hero'
import ProjectNav from '../components/ui/ProjectNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap'
import Zoom from 'react-medium-image-zoom'

export default function KilifiOpenData() {

    useEffect(() => {
        document.title = "Kilifi Data Dashboard | Michael Njogu"
    }, [])

        return (
            <div className="wrapper">     

            <Hero url="../images/projects/kilifi/kilifi-hero.jpg" alt="Hero image for Kilifi Open Data project" />

            <ProjectNav />

            <section className="content-section pb-0">
                <Container>

                    <h1 className="page-title project-title">Kilifi Data Dashboard</h1>
                    <p className="lead">Promoting open governance through data visualization.</p>

                    <div className="project-info">

                        <div className="project-info-item">
                            <h5>My Role</h5>
                            <ul>
                                <li>Wireframing</li>
                                <li>Visual Design</li>
                                <li>Prototyping &amp; Testing</li>
                            </ul>
                        </div>                 

                        <div className="project-info-item">
                            <h5>Team</h5>
                            <ul>
                                <li>John Mucheke <span>(Project Lead)</span></li>
                                <li>Geoffrey Muthami <span>(Database design &amp; APIs)</span></li>
                                <li>Dennis Mwongera <span>(Frontend - Vue.js)</span></li>
                                <li>Laurent Juma <span>(Frontend - React)</span></li>
                            </ul>
                        </div>                     

                    </div>                    

                </Container>
            </section>

            <section className="content-section pb-0" id="context">
                <Container>
                    <h2 className="section-title">Context</h2>
                    <h5>The Problem</h5>
                    <p>Kilifi County, situated in Southern Kenya, covers an area of 12,246km<sup>2</sup> and has over <strong>1400 public projects</strong> in different stages of development. The County Government lacked a digital platform to <strong>update</strong> and <strong>visualize</strong> data on the existing projects.</p>
                    <h5>Our Solution &amp; Vision</h5>
                    <p>For the first phase, we developed a simple web application to enable research assistants, dubbed <em>youth fellows</em>, to collect data on county projects. We assigned each of the 35 wards in the county to one youth fellow. Their task was to collect data and report on the status of different projects in their ward, looking at:</p>
                    <ul className="fa-ul">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>How the project was implemented – were citizens involved in the implementation?</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>The status of the project – Is it complete? Is it serving the community as intended?</li>
                    </ul>
                    <p>For the second phase, we developed a public-facing data dashboard that would display the analyzed data in a <strong>simple</strong> and <strong>readable</strong> manner.</p><p>Our vision was to pave way for a future where data can be made available to the public so that <strong>meaningful engagement</strong> can take place.</p>
                    <h5>My Role</h5>
                    <p>I created <strong>wireframes</strong> and <strong>high-fidelity mockups</strong> for the data collection tool and data dashboard. Thereafter, I created <strong>interactive prototypes</strong> to demonstrate how the applications would work.</p>
                </Container>
            </section> 

            <section className="content-section pb-0" id="process">
                <Container>
                    <h2 className="section-title">Process</h2>
                    <h5>Research &amp; Findings</h5>
                    <p>Our research team conducted workshops and training sessions to help youth fellows embrace technology during data collection exercises. They discovered that many youth fellows and fellow residents of Kilifi County were unaware of public projects in their county.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/interviewing-residents.jpg" alt="An interview with one of the youth fellows" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>An interview with one of the youth fellows</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/geodata.jpg" alt="Geodata training" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Training a youth fellow to use geodata</p>
                            </div>
                        </div>
                    </div>

                    <h5>Identifying the Key Goals</h5>
                    <p>We identified the key strategic goals:</p>
                    <ul className="fa-ul mb-5">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>We wanted to <strong>simplify</strong> the data collection process for youth fellows</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>We wanted to present the data in a <strong>simple readable manner</strong> for the public</li>
                    </ul>
                    
                    <h5>Designing the Project Questionnaire</h5>
                    <p>We came up with a questionnaire that would be used to collect project data in the field. The developers then digitized this questionnaire for the data collection application.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/questionnaire-design.png" alt="An interview with one of the youth fellows" width="800" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>An interview with one of the youth fellows</p>
                            </div>
                        </div>
                    </div>

                    <h5>Mapping the Features</h5>
                    <p>We mapped out the features to determine what would be included in the first and consequent releases.</p>
                    <div className="image-grid grid-1 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/featmap.jpg" alt="Featmap" width="800" />
                            </Zoom>
                        </div>
                    </div>

                    <h5>Wireframing the Solution</h5>
                    <p>I created up some low-fi mockups get feedback from the development team.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/ra-dashboard-wireframe.jpg" alt="Wireframe of the research assistant dashboard" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Wireframe of the research assistant dashboard</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/project-details.jpg" alt="Wireframe of the project details" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Wireframe of the project details screen</p>
                            </div>
                        </div>
                    </div>

                    <h5>High Fidelity Mockups</h5>
                    <p>I mocked up the screens in <strong>Figma</strong> and worked closely with the developers to ensure the frontend application matched the design.</p>
                    <div className="image-grid grid-2 mb-5">
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/user-login.jpg" alt="User login" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>User login</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/ra-dashboard.jpg" alt="Research assistant dashboard" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Research assistant dashboard</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/public-dashboard.jpg" alt="Homepage on the public-facing web app" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Homepage (public-facing app)</p>
                            </div>
                        </div>
                        <div className="image-grid-item">
                            <Zoom zoomMargin={40}>
                                <img loading="lazy" className="d-block" src="../images/projects/kilifi/public-project-page.jpg" alt="Project details (public-facing app)" />
                            </Zoom>
                            <div className="image-grid-item-caption">
                                <p>Project details (public-facing app)</p>
                            </div>
                        </div>
                    </div>

                </Container>
            </section>

            <section className="content-section pb-0" id="results">
                <Container>
                    <h2 className="section-title">Results</h2>
                    <p>The youth fellows were excited to see the results of their work on the public-facing dashboard. They expressed gratitude for the opportunity to take part in mapping the county projects. In the past, they had collected data by walking around the county using lots of paperwork, but with the skills acquired during the training, they looked forward to using digital data collection and reporting with confidence.</p>
                    <div className="quotes mt-4">
                        <blockquote className="quote"><p>“I’ve done manual data collection before – it was hectic! Technology has made data collection easier.”</p><cite>Sipha Oketch, Youth Fellow, Kilifi County</cite></blockquote>
                        <blockquote className="quote"><p>“Fieldwork previously involved walking around with lots of paperwork. It’s amazing to use an application to upload data real time!”</p><cite>Phenny Pendo, M &amp; E Department, Kilifi County</cite></blockquote>
                    </div>

                </Container>
            </section>

            <section className="content-section pb-0" id="challenges">
                <Container>
                    <h2 className="section-title">Challenges</h2>
                    <ul className="fa-ul">
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>I was new to the team and this was my first time working <strong>fully remotely</strong>. Initially, it was a challenge dealing with the new dynamics but I learned to adapt and maximize my productivity.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span>We had a tight deadline to deliver the application and little time to train the research assistants and supervisors to use the system. To solve this, we created a <strong>staging environment</strong> days before the launch. This also helped us catch bugs and gaps, which we addressed in the final build.</li>
                    </ul>
                </Container>
            </section>

            <section className="content-section" id="takeaways">
                <Container>
                    <h2 className="section-title">Takeaways</h2>
                    <h5>Focus on the problem</h5>
                    <p>As designers, we must identify the actual problem and state it correctly for everyone else in the team. Avoid jumping to provide a solution before properly analyzing it.</p>
                    <h5>Simplicity is key</h5>
                    <p>Always consider the goals of the users and provide the simplest way to achieve these goals.</p>
                </Container>
            </section>

        </div>            
        )
    }